import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import CenterIcon from "../CenterIcon";

const Carousel = ({
    children: slides,
    autoSlide = false,
    autoSlideDelay = 4000,
    dotColor = "rose",
    arrowsColor = "rose",
}) => {
    const [currentIdx, setCurrentIdx] = useState(0);
    const intervalRef = useRef(null);

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        if (autoSlide) {
            intervalRef.current = setInterval(handleNext, autoSlideDelay);
        }
    };

    const handlePrev = () => {
        setCurrentIdx((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
        resetInterval();
    };

    const handleNext = () => {
        setCurrentIdx((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
        resetInterval();
    };

    const goToSlide = (index) => {
        setCurrentIdx(index);
        resetInterval();
    };

    useEffect(() => {
        if (autoSlide) {
            intervalRef.current = setInterval(handleNext, autoSlideDelay);
        }
        return () => clearInterval(intervalRef.current);
    }, [autoSlide, autoSlideDelay]);

    let dotsClassName = "bg-rose-500";
    let arrowsClassName =
        "bg-rose-500 border-rose-500 dark:bg-rose-500 dark:border-rose-500 hover:bg-opacity-0 dark:hover:bg-opacity-0 dark:bg-opacity-100 bg-opacity-100 hover:text-rose-500 dark:hover:text-rose-500 clr-white border-2 smooth rounded-md pt-1.5 pb-1 px-2";
    if (arrowsColor === "primary-container") {
        arrowsClassName = "clr-primary-container smooth font-h1 hover:text-yellow-300";
    }
    if (dotColor === "primary-container") {
        dotsClassName = "bg-primary-container smooth hover:bg-yellow-300";
    }
    return (
        <div className="overflow-hidden relative w-full">
            <div
                className="flex transition-transform ease-out duration-500"
                style={{
                    transform: `translateX(${currentIdx * 100}%)`,
                }}
            >
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className="w-full relative !z-10 flex-shrink-0 px-1 md:px-10 py-10"
                    >
                        {slide}
                    </div>
                ))}
            </div>

            <div className="absolute inset-0 flex justify-between items-center mx-0 md:mx-3 pointer-events-none">
                <button
                    onClick={handleNext}
                    className={`absolute left-0 z-20 pointer-events-auto ${arrowsClassName}`}
                >
                    <CenterIcon className="" size={40} icon="uiw:caret-left" />
                </button>
                <button
                    onClick={handlePrev}
                    className={`absolute right-0 z-20 pointer-events-auto ${arrowsClassName}`}
                >
                    <CenterIcon className="" size={40} icon="uiw:caret-right" />
                </button>
            </div>

            <div className="absolute bottom-4 left-0 right-0">
                <div className="flex justify-center items-center gap-2">
                    {slides.map((_, index) => (
                        <div
                            key={index}
                            onClick={() => goToSlide(index)}
                            className={`cursor-pointer transition-all w-3 h-3 ${dotsClassName} rounded-full ${
                                currentIdx === index ? "p-2" : "bg-opacity-50"
                            }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
