import React, { useEffect, useState } from "react";

import Container from "../../components/ui/Container";

import { Icon } from "@iconify/react/dist/iconify.js";
import Carousel from "../../components/ui/carousels/Carousel";
import { description as formatDescription, printUnit } from "../../utils/ar";
import { ReactComponent as BirdFeather } from "../../assets/Updated-imagery/birdFeather.svg";
import http from "../../services/httpServices";
import { baseURL } from "../../config";
import { Link } from "react-router-dom";

import pencil from "../../assets/Updated-imagery/pencil.png";
// import { ReactComponent as SnowDecore } from "../../assets/snowDecore.svg";

const FeaturedCourseCard = ({ course, index }) => {
    return (
        <Link to={`/course/${course.id}`}>
            <div
                key={course.id}
                className="p-4 relative text-center mx-1 md:mx-5 flex-center-both flex-col"
            >
                {/* <SnowDecore className="absolute top-0 left-0 opacity-95" /> */}

                <img
                    src={`${baseURL}/${course.picture}`}
                    alt={`${course.name}#_${index}`}
                    className="mx-auto mb-4 rounded-md"
                    style={{
                        aspectRatio: "16/9",
                        objectFit: "cover",
                    }}
                />
                <div className="bg-slate-100 mx-5 w-[90%] clr-text-primary px-2 py-4 flex-center-both smooth flex-col space-y-5 dark:bg-slate-800 rounded-md shadow-md -mt-9">
                    <h3 className=" text-xl font-semibold">{course.name}</h3>
                    <p className="mt-2 text-gray-600 dark:text-gray-300 smooth">
                        {formatDescription(course.description, true)}
                    </p>
                    <p className="mt-2 font-bold text-orange-500">
                        {course.subscriptions_count < 1 ? (
                            <>
                                {course.price > 0 ? (
                                    <span className="bg-gradient-to-r from-orange-400 to-yellow-500 text-slate-100 rounded-lg py-1 px-3 space-x-2 space-x-reverse">
                                        {printUnit(course.price, "جنيه")}
                                    </span>
                                ) : (
                                    <div>
                                        <div className="bg-gradient-to-r from-orange-400 to-yellow-500 text-slate-100 rounded-lg py-1 px-3 space-x-2 space-x-reverse">
                                            كورس مجاني !
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="bg-orange-500 bg-opacity-10 border-orange-400 border rounded-md py-4 px-3 font-w-bold text-cyan-500 text-center">
                                انت مشترك
                                <br />
                                في هذا {course.isCoursesRenamed ? "الشهر" : "الكورس"}
                            </div>
                        )}
                    </p>
                </div>
            </div>
        </Link>
    );
};

const FeaturedCourses = () => {
    const [recommendedCourses, setRecommendedCourses] = useState([]);

    const getFeaturedCourses = async () => {
        try {
            const { data } = await http.get(`/api/courses/featured_courses`);
            setRecommendedCourses(data);
            // console.log("data", data);
        } catch (error) {
            // console.error(error);
        }
    };

    useEffect(() => {
        getFeaturedCourses();
    }, []);

    return (
        <>
            <section className="font-graph relative overflow-hidden ">
                <Container className="flex-center-both relative z-10 w-full">
                    <div className="flex-center-both flex flex-col lg:pt-0 lg:flex-row lg:space-x-20 lg:space-x-reverse w-full relative z-10">
                        <div className="ms-content-text pr-md-4 pr-0 relative lg:pb-20 pb-0 basis-1/2">
                            <div className="lg:w-64 w-44 absolute top-1/2 opacity-30 right-1/2 transform translate-x-1/2 -translate-y-1/2 ">
                                <BirdFeather />
                            </div>
                            <div className="relative z-10">
                                <div className=" font-big  relative py-8 flex-center-both flex-row space-x-8 space-x-reverse">
                                    <div className="w-10">
                                        <img src={pencil} alt="pencil" className="w-14" />
                                    </div>
                                    <div className="relative">
                                        <div className="relative font-bigmax font-semibold z-10 text-center">
                                            <span>الكورسات</span>{" "}
                                            <span className="text-orange-500">المُقترحة</span>
                                        </div>
                                    </div>
                                    <div className="w-10">
                                        <img src={pencil} alt="pencil" className="w-14" />
                                    </div>
                                </div>
                                <div className="text-center text-gray-500 dark:text-gray-300 smooth font-h3 font-w-medium max-w-md mx-auto">
                                    استاذك وليد محسن! رشحلك أفضل الكورسات الي هتخليك تفرق عن باقي
                                    زمايلك اجهز يا طالبي لرحلة نجاحك على منصة استاذي ! هنبسطك في
                                    رحلتك
                                </div>
                            </div>
                        </div>
                        <div className="py-20 w-full relative basis-full md:basis-1/2 max-w-3xl">
                            <div className="w-full bg-gradient-to-tr from-orange-400 to-yellow-500 rounded-xl shadow-2xl min-h-[20rem] flex-center-both px-2 mx:px-0">
                                {/* <CourseCard
                                        name="الصف الدراسي الأول"
                                        description="جميع كورسات الصف الأول الثانوي"
                                        isYear={1}
                                        isStaticPicture={year1}
                                    /> */}
                                {recommendedCourses.length > 0 ? (
                                    <div className="w-full">
                                        <Carousel
                                            dotColor="primary-container"
                                            arrowsColor="primary-container"
                                            autoSlide={true}
                                            autoSlideDelay={3000}
                                        >
                                            {recommendedCourses.map((course, index) => (
                                                <FeaturedCourseCard course={course} index={index} />
                                            ))}
                                        </Carousel>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="font-h2 col-span-1 md:col-span-2 lg:col-span-3 py-20">
                                            <span className="flex-center-both space-x-3 space-x-reverse">
                                                <span className="font-h1 flex-center-both text-yellow-900 dark:text-yellow-400">
                                                    <Icon icon="hugeicons:loading-01" />
                                                </span>
                                                <span className="text-center">
                                                    سيتم اضافة الكورسات المقترحة قريبًا
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {/* <div className="px-1 flex-center-both md:px-20 py-5 md:py-10 bg-primary-container smooth clr-text-primary shadow-2xl rounded-xl"></div> */}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default FeaturedCourses;
