import React, { useContext, useEffect, useState } from "react";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { useLocation } from "react-router-dom";
import { adminPath } from "../../../services/defaultSettings";
import CenterIcon from "../../../components/ui/CenterIcon";
import AuthContext from "../../../context/AuthContext";
import Ballons from "../../../components/ui/Ballons";

const TempNotifcation = () => {
    const [isHidden, setIsHidden] = useState(false);
    const [className, setClassName] = useState("");
    // const { tempNotificationId, setTempNotificationId } = useContext(useContext);

    const [tempNotificationId, setTempNotificationId] = useState(0);

    const { token } = useContext(AuthContext);

    const getTempNotificationId = async () => {
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: notifcationId } = await http.get("/api/get_temp_notification_id", config);
            setTempNotificationId(notifcationId);
        } catch (e) {}
    };
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
        if (token || auth.getToken()) {
            getTempNotificationId();
        }
    }, []);
    return (
        <>
            {tempNotificationId === 30000 ? (
                <>
                    {/* <Ballons /> */}
                    <div
                        className={`absolute w-full top-[5rem] right-0 left-0 px-2 py-2 ${className} ${
                            isHidden ? "!hidden" : ""
                        }`}
                    >
                        <div className="relative rounded-md  smooth text-white p-5 text-center absolute-celebration">
                            <div className="flex-center-both flex-col space-y-2 relative z-10">
                                <div className="font-h3 font-w-bold">
                                    اهلًا اهلًا باللي كسب هدية العمره مبروك🥺🥺❤️❤️
                                </div>
                            </div>
                            <button
                                className="absolute left-2 top-2 p-2 flex-center-both rounded-md  bg-sky-100 bg-opacity-30 hover-shadow smooth z-10"
                                onClick={() => setIsHidden(true)}
                            >
                                <CenterIcon nY="0" icon="fa:close" />
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                ""
            )}
            {[60, 5000, 30, 100, 50].includes(tempNotificationId) ? (
                <div
                    className={`absolute w-full top-[5rem] right-0 left-0 px-2 py-2 ${className} ${
                        isHidden ? "!hidden" : ""
                    }`}
                >
                    <div className="relative rounded-md  smooth text-white p-5 text-center absolute-celebration">
                        <div className="flex-center-both flex-col space-y-2 relative z-10">
                            <div className="font-h3 font-w-bold">
                                بُص كِده على المحفظة و ابعتلنا زادت كام 🥺🥺❤️❤️
                            </div>
                        </div>
                        <button
                            className="absolute left-2 top-2 p-2 flex-center-both rounded-md  bg-sky-100 bg-opacity-30 hover-shadow smooth z-10"
                            onClick={() => setIsHidden(true)}
                        >
                            <CenterIcon nY="0" icon="fa:close" />
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default TempNotifcation;
